import ReactGA from "react-ga";
const TRACKING_ID = "G-5KJ1R2YTL9";
ReactGA.initialize(TRACKING_ID);

export const useAnalyticsEventTracker = (category = "Products") => {
  const eventTracker = (action = "click", label = "test label") => {
    ReactGA.event({ category, action, label });
    console.log("Analytics eventTracker");
  };
  return eventTracker;
};
