import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useAnalyticsEventTracker } from "components/Analytics/index";
import { Tag } from "antd";
import StartUp from "../../assets/images/common/startup-menu.svg";

import {
  InterviewService,
  ResumeChecker,
  ArrowDown,
  ResumeScout,
  DescriptionScanner,
  TalentSelector,
  Jobpost,
  ResumeBuilder,
  LinkOptimization,
  ResumeJD,
  Enterprice,
  ItService
} from "constants/svgLogos";
import "animate.css";
import "components/Header/header.scss";
import FadeUp from "components/FadeUp/index";
import FadeDown from "components/FadeDown/index";

const ProductPopup = ({
  popupListData,
  navigate,
  closeSideBar,
  setDropdown,
  title
}) => {
  const gaEventTracker = useAnalyticsEventTracker("Products");

  return (
    <div className="headerProductPopup">
      <div
        className="headerProductPopupContainer"
        style={{ gridTemplateColumns: title == "solution" && "1fr" }}
      >
        {Object.keys(popupListData).map((key) => (
          <div key={key}>
            <div style={{ display: "flex" }}>
              {title !== "solution" && (
                <>
                  <div>
                    <h2>{popupListData[key].productTitle}</h2>
                  </div>

                  <div className="headerProductPopupTagContainer">
                    <Tag color={popupListData[key].tagClr}>
                      {popupListData[key].tagContent}
                    </Tag>
                  </div>
                </>
              )}
            </div>
            <ul>
              {popupListData[key].data.map((data, i) => (
                <li
                  className="headerProductPopupList"
                  key={i}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    // eslint-disable-next-line
                    if (typeof window !== undefined) {
                      // navigate(data.location);
                      window.location.href =
                        window.location.origin + `${data.location}`;
                    }
                    closeSideBar();
                    setDropdown(false);
                    gaEventTracker("Click", data.service);
                  }}
                >
                  {data.service === "Start Ups" ? (
                    <img
                      src={data.img}
                      style={{ width: "45px", height: "45px" }}
                    />
                  ) : (
                    <div>{data.img}</div>
                  )}
                  <div className="popupList" style={{ width: "200px" }}>
                    <span>{data.service}</span>
                    <p>{data.feature}</p>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </div>
  );
};

const index = () => {
  const navigate = useNavigate();

  const popupListData = {
    popupListData1: {
      productTitle: "Platform",
      tagClr: "green",
      tagContent: "Organization",
      data: [
        {
          img: <InterviewService />,
          service: "Interview As A Service",
          feature: "Our AI powered intelligence platform to hire candidates",
          location: "/products/interview-as-a-service"
        }
      ]
    },
    popupListData2: {
      productTitle: "Recruiters",
      tagClr: "green",
      tagContent: "Free",
      data: [
        {
          img: <Jobpost />,
          service: "Job Posting Management",
          feature:
            "Manage postings across social media platforms from one place",
          location: "/products/job-posting-management"
        },
        {
          img: <ResumeScout />,
          service: "Resume Data Scout",
          feature: "Visualise and validate candidates resumes",
          location: "/products/resume-data-scout"
        },
        {
          img: <DescriptionScanner />,
          service: "Job Description Scanner",
          feature: "Enhance job descriptions to attract more candidates",
          location: "/products/job-description-scanner"
        },
        {
          img: <TalentSelector />,
          service: "Top Talent Selector",
          feature: "Get top resumes matching with your job description",
          location: "/products/top-talent-selector"
        }
      ]
    },
    popupListData3: {
      productTitle: "Candidates",
      tagClr: "green",
      tagContent: "Free",
      data: [
        {
          img: <ResumeChecker />,
          service: "ATS Resume Checker",
          feature: "Optimize your resume and get personalised recommendations",
          location: "/products/ats-resume-checker"
        },
        {
          img: <ResumeBuilder />,
          service: "AI Resume Builder",
          feature: "Build your resume with top quality templates",
          location: "/products/ai-resume-builder"
        },
        {
          img: <LinkOptimization />,
          service: "AI Linkedin Optimization",
          feature: "Get more reach by optimizing your linkedIn profile",
          location: "/products/ai-linkedin-optimization"
        },
        {
          img: <ResumeJD />,
          service: "Resume-JD Syncer",
          feature: "Match your resume with job description",
          location: "/products/resume-jd-syncer"
        }
      ]
    }
  };

  const SolutionDataList = {
    popupListData1: {
      productTitle: "Platform",
      tagClr: "red",
      tagContent: "Organization",
      data: [
        {
          img: StartUp,
          service: "Start Ups",
          feature: "Streamlined Hiring Solutions for Startups",
          location: "/solutions/start-ups"
        },
        {
          img: <ItService />,
          service: "IT Services",
          feature: "Efficient Recruitment for IT Services",
          location: "/solutions/it-services"
        },
        {
          img: <Enterprice />,
          service: "Enterprises",
          feature: "Optimized Hiring for Large Enterprises",
          location: "/solutions/enterprise"
        }
      ]
    }
  };

  const [dropdown, setDropdown] = useState(false);
  const [slnDropdown, setSlnDropdown] = useState(false);
  const [sideMenu, setSideMenu] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isMouseLeave, setIsMouseLeave] = useState(false);
  // const [impact, setImpact] = useState(true);
  // const [solution, setSolution] = useState(true);

  const dropdownActive = () => {
    setDropdown(!dropdown);
    setIsMouseLeave(false);
  };

  const slnDropdownActive = () => {
    setSlnDropdown(!dropdown);
    setIsMouseLeave(false);
  };

  useEffect(() => {
    if (window.innerWidth < 992) {
      setDropdown(true);
    }

    const resizeWindow = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", resizeWindow);
    return () => window.removeEventListener("resize", resizeWindow);
  }, []);

  const onMouseLeave = () => {
    setDropdown(false);
    setIsMouseLeave(true);
  };

  const onMouseSlnDropDownLeave = () => {
    setSlnDropdown(false);
    setIsMouseLeave(true);
  };

  const sideBar = () => {
    setSideMenu(!sideMenu);
    setDropdown(true);
    setSlnDropdown(true);
  };

  function closeSideBar() {
    setSideMenu(false);
  }

  useEffect(() => {
    if (sideMenu) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [sideMenu]);

  return (
    // <header className="header animate__animated animate__fadeInDown">
    <header className="header">
      <div className="container headerContainer">
        <div className="headerLogo">
          <a href="/">
            <img
              src="https://dj4gk1hnrapgw.cloudfront.net/logo.svg"
              alt="Logo"
            />
          </a>
        </div>
        <ul
          className="headerList"
          style={{
            right: sideMenu ? "0" : "-100%",
            transition: "all 0.5s ease"
          }}
        >
          <li
            className="headerProduct"
            onClick={() => setSideMenu(false)}
            onMouseEnter={() => {
              windowWidth > 992 && dropdownActive();
            }}
            onMouseLeave={() => {
              windowWidth > 992 && onMouseLeave();
            }}
          >
            <Link to="/products">
              Products
              {dropdown ? (
                <FadeUp
                  duration={"0.5"}
                  position={windowWidth > 992 && true}
                  left={
                    windowWidth > 1150
                      ? "15%"
                      : windowWidth > 1010
                      ? "3%"
                      : windowWidth > 992
                      ? "1%"
                      : ""
                  }
                >
                  <ProductPopup
                    popupListData={popupListData}
                    navigate={navigate}
                    closeSideBar={closeSideBar}
                    setDropdown={setDropdown}
                    title="product"
                  />
                </FadeUp>
              ) : (
                isMouseLeave &&
                windowWidth > 992 && (
                  <FadeDown
                    left={
                      windowWidth > 1150
                        ? "15%"
                        : windowWidth > 1010
                        ? "3%"
                        : windowWidth > 992
                        ? "1%"
                        : ""
                    }
                  >
                    <div onMouseEnter={() => setDropdown(false)}>
                      <ProductPopup
                        popupListData={popupListData}
                        navigate={navigate}
                        closeSideBar={closeSideBar}
                        setDropdown={setDropdown}
                        title="product"
                      />
                    </div>
                  </FadeDown>
                )
              )}
            </Link>

            <div
              className="arrowDown"
              style={{
                transform: dropdown ? "rotate(180deg)" : "rotate(0deg)",
                transition: "all 0.5s ease"
              }}
            >
              <ArrowDown />
            </div>
          </li>

          <li
            className="headerProduct"
            onClick={() => setSideMenu(false)}
            onMouseEnter={() => {
              windowWidth > 992 && slnDropdownActive();
            }}
            onMouseLeave={() => {
              windowWidth > 992 && onMouseSlnDropDownLeave();
            }}
          >
            <Link to="/products">
              Solutions
              {slnDropdown ? (
                <FadeUp
                  duration={"0.5"}
                  position={windowWidth > 992 && true}
                  left={
                    windowWidth > 1150
                      ? "36%"
                      : windowWidth > 1010
                      ? "32%"
                      : windowWidth > 992
                      ? "30%"
                      : ""
                  }
                >
                  <ProductPopup
                    popupListData={SolutionDataList}
                    navigate={navigate}
                    closeSideBar={closeSideBar}
                    setDropdown={setSlnDropdown}
                    title="solution"
                  />
                </FadeUp>
              ) : (
                isMouseLeave &&
                windowWidth > 992 && (
                  <FadeDown
                    left={
                      windowWidth > 1150
                        ? "15%"
                        : windowWidth > 1010
                        ? "3%"
                        : windowWidth > 992
                        ? "1%"
                        : ""
                    }
                  >
                    <div onMouseEnter={() => setSlnDropdown(false)}>
                      <ProductPopup
                        popupListData={SolutionDataList}
                        navigate={navigate}
                        closeSideBar={closeSideBar}
                        setDropdown={setSlnDropdown}
                        title="solution"
                      />
                    </div>
                  </FadeDown>
                )
              )}
            </Link>

            <div
              className="arrowDown"
              style={{
                transform: slnDropdown ? "rotate(180deg)" : "rotate(0deg)",
                transition: "all 0.5s ease"
              }}
            >
              <ArrowDown />
            </div>
          </li>

          <li onClick={() => setSideMenu(false)}>
            <a href="/integration">Integration</a>
          </li>
          <li onClick={() => setSideMenu(false)}>
            <a href="/pricing">Pricing</a>
          </li>
          <li onClick={() => setSideMenu(false)}>
            <a href="/contact-us">Contact</a>
          </li>
          <li onClick={() => setSideMenu(false)}>
            <a
              href="https://blogs.quickrecruit.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              Blogs
            </a>
          </li>

          {/* <li onClick={() => setSideMenu(false)}>
            <Link to="/product">Suits</Link>
          </li> */}
        </ul>
        <div className="loginSignUp">
          <Link to="https://app.quickrecruit.com/signin">
            <button className="headerLogIn">Log In</button>
          </Link>
          <a href="/get-started">
            <button className="commonBtn headerSignUp">Get Started</button>
          </a>
          <img
            className="headerHamburger"
            onClick={sideBar}
            src={
              sideMenu
                ? "https://dj4gk1hnrapgw.cloudfront.net/header_close.svg"
                : "https://dj4gk1hnrapgw.cloudfront.net/header_hamburger.svg"
            }
            alt="Hamburger"
          />
        </div>
      </div>
    </header>
  );
};

export default index;
